

import {Component} from 'react';
import shadow from '../../../assets/img/sombra_3.png'

export default class CiteHome extends Component 
{
    render()
    {
        return (
            <>
            <section id="bloque_home_6">
                <div className="grid_modulos">
                <p>Dedicated to the vision of growing a sustainable ecotourism industry, we work toward protection of the environment with an economic benefit for its communities. Hiring local guides and vendors promotes sustainable, long-term stability for Yellowstone's economy. We participate in grassroots efforts to encourage sustainable practices in industry, business, and recreation. We teach and practice the Leave No Trace outdoor ethics curriculum. The food we serve is healthy, organic, and sourced locally to the extent possible. Product waste including plastics, aluminum, paper, and glass is recycled through participation in the Green Parks Plan.</p>
                </div>
            </section>
            <section id="bloque_home_7">
                <p>
                Thousands of tired, nerve-shaken, over-civilized people are beginning to find out that going to the mountains is going home; that wildness is a necessity.”
                </p>
                <p>
                John Muir
                </p>
                <img className="sombra" src={shadow} alt=""/>
            </section>


            </>
        )
    }
}