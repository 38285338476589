import  {Component} from 'react'
import Shape1 from '../../assets/svg/shape_1'
import FbIcon from '../../assets/svg/facebook'
import InstIcon from '../../assets/svg/instagram'
import TwitterIcon from '../../assets/svg/twitter'
import WspIcon from '../../assets/svg/whatsapp'
import MadeBy from './made-by'

export default class MainFooter extends Component
{
    render()
    {
        return (
            <>
                <section id="main_footer">
                    <div className="shape_3">
                            <Shape1/>
                    </div>
                    <div className="textos">
                            <h4>
                                    Summer Exploring
                            </h4>
                            <p>
                            Mountains are probably our favourite landscapes to photograph. There’s nothing quite like being out in the fresh air surrounded by natures giants. They also offer some of the most epic, awe-inspiring views!
                            </p>
                            <div className="redes">
                                <i>
                                    <InstIcon/>
                                </i>
                                <i>
                                    <FbIcon/>
                                </i>
                                <i>
                                    <TwitterIcon/>
                                </i>
                                <i>
                                    <WspIcon/>
                                </i>
                            </div>
                    </div>
                    <div className="menu">
                            <ul>
                                <li onClick={()=>{this.props.navOnepage('header-home');}}>Home</li>
                                <li onClick={()=>{this.props.navOnepage('bloque_home_1');}}>Services</li>
                                <li onClick={()=>{this.props.navOnepage('bloque_home_2');}}>Offers</li>
                                <li onClick={()=>{this.props.navOnepage('bloque_home_6');}}>About us</li>
                                <li onClick={()=>{this.props.navOnepage('bloque_contact');}}>Contact</li>  
                            </ul>
                    </div>
                    <div className="clear"></div>
                    <MadeBy/>
                </section>

            </>
        )
    }
}