import {Component} from 'react';
import sliderHome from '../../../assets/img/slider_home_2.jpg'
import sliderHomeMovil from '../../../assets/img/slider_home_movil_2.jpg'
import Shape1 from '../../../assets/svg/shape_1';
import Shape2 from '../../../assets/svg/shape_2';


export default class HeaderHome extends Component 
{
    render()
    {
        return (
            <>
                <header id="header-home" className="header">
                    <div className="shape_1">
                            <Shape1/>
                    </div>
                    <div className="textos">
                            <h1>
                                IT´S TIME FOR HIKING</h1>
                            <h2>Humans can feel truly wild</h2>
                            <button type="button" name="button" onClick={()=>{this.props.navOnepage('bloque_contact');}}>CONTACT US</button>
                            <p>
                            Some mountains only require a good pair of shoes. Others require an entire team to conquer. Knowing which is which, is the key to success.
                            </p>
                    </div>
                    <picture>
                            <source srcSet={sliderHomeMovil} media="(max-width: 600px)"/>
                            <source srcSet={sliderHome}/>
                            <img src={sliderHome} alt=""/>
                    </picture>
                    <div className="shape_2">
                            <Shape2/>
                    </div>
                </header>

            </>
        )
    }
}
