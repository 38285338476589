import {Component} from 'react';
import Shape1 from '../../../assets/svg/shape_1';
import Shape2 from '../../../assets/svg/shape_2';
import $ from 'jquery'

export default class Subscribe extends Component 
{
    constructor(props)
    {
        super(props)
        
        this.checkForm=this.checkForm.bind(this)
    }
    checkForm()
    {
        let send=true
        
        $('.success-mesagge-2').fadeOut(0)
        $('.error-mesagge-2').fadeOut(0)
        $('.input').removeClass('error')
        if($('#email-subs').val()==='' || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test($('#email-subs').val())===false)
        {
            send=false;
        }
        if(send===true)
        {
            document.getElementById('subs-form').reset();
            $('.success-mesagge-2').fadeIn(0)
        }
        else if(send===false)
        {
            $('.error-mesagge-2').fadeIn(0)
        }

    }
    render()
    {
        return (
            <>
                    <section id="bloque_home_8">
                        <div className="shape_3">
                                <Shape1/>
                        </div>
                        <div className="textos">
                                <h3>
                                        Receive our latest news
                                </h3>
                                <p>
                                Leave us your email and we will send you the most exciting adventures.
                                </p>
                                <div className="success-mesagge-2" >Your are subscribed</div>
                                <div className="error-mesagge-2" >Invalid email</div>
                                <div className="newsletter">
                                    <form id="subs-form">
                                        <input type="email" id="email-subs"  name="email"  placeholder="Your Email"/>
                                        <button type="button" onClick={this.checkForm}  name="button">SUBSCRIBE</button>
                                    </form>
                                    <div className="clear"></div>
                                </div>
                        </div>
                        <div className="shape_4">
                            <Shape2/>
                        </div>
                    </section>

            </>
        )
    }
}