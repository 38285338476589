
import {Component} from 'react'

export default class MadeBy extends Component 
{
    render()
    {
        return(
            <>
                <style>{`
                        .made-by-me {
                            font-family:'arial';
                            position:absolute;
                            bottom:2vw;
                            margin-top:2vw;
                            width:100%;
                            text-align:center;
                        }
                        .made-by-me a {
                            font-size:1.2vw;
                            color:#ffffff;
                            text-decoration:none;
                        }
                        
                        .made-by-me a:hover {
                            text-decoration:underline;
                        }
                        @media (max-width:600px)
                        {
                            .made-by-me {
                                margin-bottom:10vw;
                            }
                            .made-by-me a {
                                font-size:2.8vw;
                            }
                        }
                    `}</style>

                <p className="made-by-me">
                    <a href="https://www.sebastianneumann.fr/" target="_blank" rel="noreferrer">
                        Made it by Sebastian Neumann
                    </a>
                </p>
                
            </>
        )
    }
}