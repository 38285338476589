

const Shape1=()=>
{
	return (

		<svg viewBox="0 0 612 80.392">
						<path d="M0.667,68.362c5.197-0.079,9.003-6.138,15.021-5.217c1.084,0.166,4.568,2.678,5.643,3.219
							c1.99,1.003,4.32,2.19,6.669,2.662c12.588,2.527,20.714-5.187,32.185-2.146C72.027,70.018,82.302,63,94.534,65.664
							c2.289,0.499,3.758,2.103,6.799,2.714c2.665,0.535,6.702-0.731,9.158-1.207c5.71-1.106,5.553-0.968,9.694-0.293
							c2.836,0.462,5.898,1.194,9.181,1.484c9.577,0.848,22.424,12.244,32.131,6.68c5.835-3.346,4.379-7.687,12.503-8.048
							c5.063-0.226,10.949,2.193,15.998,0.699c1.469-0.436,4.29-3.243,5.153-3.366c4.445-0.631,2.893,1.943,6.15,3.218
							c3.209,1.254,6.143,1.326,9.398,2.687c3.255,1.36,3.98,2.609,7.449,3.464c4.996,1.231,10.254,1.719,15.188,2.649
							c6.319,1.192,10.908-0.593,17.331,0.018c5.665,0.538,9.057,3.224,14.701,4.533c4.291,0.996,8.805,0.072,13.296,0.802
							c7.952,1.293,19.146,2.48,27.203-1.305c2.49-1.169,2.285-3.069,5.649-4.028c2.039-0.581,5.161-0.002,7.301-0.002
							c5.693-0.001,11.52,0.366,17.198,0.016c8.721-0.539,17.04-3.122,26.535-2.566c1.308,2.684,22.438-2.079,28-1.333
							c0.988,4.322,6.459,3.321,10.116,4.05c6.531,1.301,7.157,2.268,13.437-0.887c4.865-2.443,5.193-3.533,11.23-2.629
							c4.83,0.724,8.942,2.61,12.864,1.18c3.405-1.242,6.089-5.34,10.318-6.013c3.575-0.569,7.679,0.825,12.146,0.161
							c3.717-0.553,9.549-0.277,13.368-1.28c3.065-0.805,4.996-3.46,8.538-3.968c0.14,0.638,0.017,1.208,0.182,1.84
							c5.931,1.28,15.19,1.574,20.618-1.07c5.52-2.688,5.82-9.162,13.299-6.832c4.744,1.478,7.981,5.868,13.286,6.68
							c5.916,0.906,13.668,1.688,19.378,1.317c2.163-0.141,5.669-1.557,7.32-1.349c3.944,0.497,4.151,3.173,7.338,4.063
							c8.01,2.238,12.61-5.648,19.342-6.046c2.214-0.13,3.608,1.379,6.669,1.329c2.448-0.04,5.888-2.421,8-2.029
							c2.586,0.48,2.601,2.835,4.685,4.047c1.9,1.105,10.423,2.651,12.649,3.317L612,0H-0.2L0.667,68.362z"/>
		</svg>
	)
}

export default Shape1

