import  {Component} from 'react'
import logo from '../../assets/img/logo-2.png'
import logoWhite from '../../assets/img/logo-blanco.png'
import OpenIcon from '../../assets/svg/open'
import CloseIcon from '../../assets/svg/close'
import $ from 'jquery'





export default class MainHeader extends Component
{
  constructor(props)
  {
    super(props)
    this.activateAnimation=this.activateAnimation.bind(this)

    this.state={
      logo:logo
    }
  }

  animation()
  {
      var scroll=$(window).scrollTop();
      if($(window).width()<=600)
      {
          if(scroll>=100)
          {
            $('#nav_principal').css({'background':'rgba(0,0,0,0.4)'});
            $('#nav_principal .botones svg path').css({'fill':'#ffffff'})
            this.setState({
              logo:logoWhite
            })
         
          }
          else
          {
            $('#nav_principal').css({'background':'rgba(255,225,255,0)'});
            $('#nav_principal .botones svg path').css({'fill':'#7f7770'});
            this.setState({
              logo:logo
            })
          }
      }
      else
      {
        if(scroll>=100)
        {
          $('#nav_principal').css({'background':'rgba(0,0,0,0.4)','padding':'0vw 0 1.2vw 0','color':'#fff'});
          $('#nav_principal').find('.logo_principal').css({'width':'4vw','top':'1vw'});
          $('#nav_principal li').css({'color':'#fff'});
          this.setState({
            logo:logoWhite
          })
        }
        else
        {
          $('#nav_principal').css({'background':'rgba(255,225,255,0)','padding':'2vw 0 3vw 0','color':'#54524f'});
          $('#nav_principal').find('.logo_principal').css({'width':'7vw','top':'2vw'});
          $('#nav_principal li').css({'color':'#7f7770'});
          this.setState({
            logo:logo
          })
        }
      }
  }
  activateAnimation()
  {
      $(window).off('scroll');
      $(window).on('scroll',()=>{this.animation()});
      if($(window).width()<=600)
      {        
          this.closeNav();
      }
  }
  
    openNav()
    {
            $('.abrir').fadeOut(300);
            $('.cerrar').fadeIn(300);
            $('#nav_principal ul').css({'top':'0'});
    }
    
    closeNav()
    {        
        if($(window).width()>600)return
            $('.cerrar').fadeOut(300);
            $('.abrir').fadeIn(300);
            $('#nav_principal ul').css({'top':'-200vh'});
    }
    componentDidMount()
    {
      this.activateAnimation()
    }
    render()
    {
        return (
            <>
                <nav id="nav_principal">
                    <div className='logo_principal'>
                            <img src={this.state.logo} alt="Summer exploring logo"/>
                    </div>
                    <ul>
                            <li onClick={()=>{this.props.navOnepage('header-home'); this.closeNav()}}>Home</li>
                            <li onClick={()=>{this.props.navOnepage('bloque_home_1'); this.closeNav()}}>Services</li>
                            <li onClick={()=>{this.props.navOnepage('bloque_home_2'); this.closeNav()}}>Offers</li>
                            <li onClick={()=>{this.props.navOnepage('bloque_home_6'); this.closeNav()}}>About us</li>
                            <li onClick={()=>{this.props.navOnepage('bloque_contact'); this.closeNav()}}>Contact</li>
                    </ul>
                    <div className="botones abrir" onClick={this.openNav}>
                        <OpenIcon/>
                    </div>
                    <div className="botones cerrar" onClick={this.closeNav}>
                        <CloseIcon/>
                    </div>
                </nav>

            </>
        )
    }
}


