import {Component} from 'react';
import servicio1 from '../../../assets/img/servicio_1.jpg'
import servicio2 from '../../../assets/img/servicio_2.jpg'
import servicio3 from '../../../assets/img/servicio_3.jpg'
import sombra_1 from '../../../assets/img/sombra_1.png'

export default class LetsGo extends Component 
{
    render()
    {
        return (
            <>
                <section id="bloque_home_1">
                    <h2>LET´S GO</h2>
                    <p>
                    Don’t confuse the hike with the destination. The hike is about the sounds, the smells, the sights. It’s a complete sensory experience… But yes, that waterfall is also pretty freaking awesome.
                    </p>
                    <div className="modulos">
                        <div className="modulo_servicio">
                            <div className="contenedor_img">
                                    <img src={servicio1} alt=""/>
                            </div>
                            <h3>Hiking</h3>
                            <p>Go out, go out I beg of you And taste the beauty of the wild. Behold the miracle of the earth with all the wonder of a child.</p>
                        </div>
                        <div className="modulo_servicio">
                            <div className="contenedor_img">
                                    <img src={servicio2} alt=""/>
                            </div>
                            <h3>Weekly programme</h3>
                            <p>slow down when hiking. The rhythm of nature is more leisurely. The sun comes up, it moves across the sky, and you begin to synchronize to that rhythm.</p>
                        </div>
                        <div className="modulo_servicio">
                            <div className="contenedor_img">
                                    <img src={servicio3} alt=""/>
                            </div>
                            <h3>Excursions</h3>
                            <p>The woods are lovely, dark and deep. But I have promises to keep, and miles to go before I sleep.</p>
                        </div>
                    </div>
                    <button type="button" name="button"  onClick={()=>{this.props.navOnepage('bloque_contact');}}>KNOW MORE</button>
                    <img className="sombra" src={sombra_1} alt=""/>
                </section>

            </>
        )
    }
}