import MainHeader from './components/structure/main-header'
import MainFooter from './components/structure/main-footer'
import HomePage from './components/pages/home';
import $ from 'jquery'
import './assets/css/main.css'
import {Component} from 'react'



export default class App extends Component
{
    constructor(props)
    {
          super(props);
          this.navOnepage=this.navOnepage.bind(this);
    }
    navOnepage(idElem)
    {   
        let posicion=$('#'+idElem).position().top;
        let diff=$('#nav_principal').height()
        $("html, body").animate({'scrollTop':posicion-diff},1000);
    }
    render()
    {
        return (
          <>
            <MainHeader navOnepage={this.navOnepage}/>            
            <HomePage navOnepage={this.navOnepage}/>
            <MainFooter navOnepage={this.navOnepage}/>
          </>
        );
    }
}

