

import {Component} from 'react';
import HeaderHome from '../blocks/home/header-home';
import LetsGo from '../blocks/home/lets-go';
import Promo from '../blocks/home/promo';
import Hiking from '../blocks/home/hiking';
import CiteHome from '../blocks/home/cite';
import Subscribe from '../blocks/home/subscribe';
import Contact from '../blocks/home/contact';

export default class HomePage extends Component 
{
    componentDidMount()
    {
        document.title='Summer exploring'
    }
    render()
    {
        return (
            <div id="pagina_home">
                <HeaderHome  navOnepage={this.props.navOnepage}/>
                <LetsGo navOnepage={this.props.navOnepage}/>
                <Promo navOnepage={this.props.navOnepage}/>
                <Hiking/>
                <CiteHome/>
                <Subscribe/>
                <Contact/>
            </div>
        )
    }
}