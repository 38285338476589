import {Component} from 'react';
import $ from 'jquery'

export default class Contact extends Component 
{
    constructor(props)
    {
        super(props)
        
        this.checkForm=this.checkForm.bind(this)
    }
    checkForm()
    {
        let send=true
        
        $('.success-mesagge').fadeOut(0)
        $('.error-mesagge').fadeOut(0)
        $('.input').removeClass('error')
        if($('#name').val()==='')
        {
            $('#name').addClass('error');
            send=false;
        }
        if($('#message').val()==='')
        {
            $('#message').addClass('error');
            send=false;
        }
        if($('#email').val()==='' || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test($('#email').val())===false)
        {
            $('#email').addClass('error');
            send=false;
        }
        if(send===true)
        {
            document.getElementById('contact-form').reset();
            $('.success-mesagge').fadeIn(0)
        }
        else if(send===false)
        {
            $('.error-mesagge').fadeIn(0)
        }

    }
    render()
    {
        return (
            <>
                    <section id="bloque_contact">
                        <div className="textos">
                                <h3>
                                        Contact us
                                </h3>
                                <p>
                                THANK YOU for your interest in our guide service! Inquire about a date or receive additional information.
                                </p>
                                
                                <div className="success-mesagge" >Your message was sent successfully</div>
                                <div className="error-mesagge" >Some errors have occurred</div>
                                <div className="form">
                                    <form id="contact-form">
                                        <input type="text" className="input" id="name" name="name"  placeholder="Your name"/>
                                        <input type="email" className="input" id="email" name="email"  placeholder="Your e-mail"/>
                                        <textarea name="message" className="input" id="message" placeholder="Your message"></textarea>
                                        <button type="button" onClick={this.checkForm} name="button">SEND MESSAGE</button>
                                    </form>
                                </div>
                        </div>
                    </section>

            </>
        )
    }
}