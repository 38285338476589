import {Component} from 'react'
import banner1 from '../../../assets/img/banner_1.jpg'
import banner2 from '../../../assets/img/banner_2.jpg'

export default class Promo extends Component 
{
    render()
    {
        return(
            <>
                <section id="bloque_home_2">
                    <div className="imagen">
                            <img src={banner1} alt="Discount up to 50% All excursions"/>
                    </div>
                    <div className="textos">
                            <h3>
                                    Discount up to 50% All excursions
                            </h3>
                            <p>
                            This summer enjoy a generous 50% discount on all our services. Don't miss out on this incredible opportunity and choose your adventure now. contact us and we will advise you completely free of charge for you.
                            </p>
                            <button type="button" name="button" onClick={()=>{this.props.navOnepage('bloque_contact');}}>CONTACT</button>
                    </div>
                    <div className="clear"></div>
                </section>
                <section id="bloque_home_3">
                    <div className="textos">
                            <h3>
                            August's promo:<br/> Buy 1 get 1 free!
                            </h3>
                            <p>
                            Only for the month of August we have the amazing 2 X 1 promotion. Tell your friends, by hiring any of our services you can accompany them with the best adventure of your life. Contact us for more information.
                            </p>
                            <button type="button" name="button" onClick={()=>{this.props.navOnepage('bloque_contact');}}>CONTACT</button>
                    </div>
                    <div className="imagen">
                        <img src={banner2} alt="Discount up to 50% All excursions"/>
                    </div>
                    <div className="clear"></div>
                </section>


            </>
        )
    }
}