
const Shape2=()=>
{
	return(

		<svg viewBox="0 0 612 76.267">
			<path d="M0.5,11.267c5.214,0.854,9.732,1.755,15.413,2.087c1.31,3.329,6.072,3.055,8.978,4.011
				c5.931,1.952,4.543,0.792,9.598-1.598c3.118-1.474,8.117-1.285,11.5-1.623c4.321-0.431,8.044,1.613,11.925,1.21
				c0.637,7.489,19.294-2.959,24,0.499c0.006,0.576,0.169,1.257,0.163,1.839c3.551,0.268,5.768-0.762,9.036-0.924
				c3.438-0.17,6.413,0.947,9.889,0.5c3.09-0.398,5.387-1.869,8.5-2c4.113-0.173,6.367,2.822,8.099-2.923
				c3.703-0.813,7.686,1.843,11.901,1.435c4.057-0.394,8.89-0.806,12.988-1.523c4.657-0.815,8.072-2.219,13.026-2.462
				c4.083-0.201,8.755-2.956,12.486-2.538c3.959,0.444,7.174,4.895,10.613,6.373c4.3,1.848,8.114,0.167,12.387,0.629
				c1.894,0.205,3.438,1.632,5.113,1.897c2.044,0.323,3.729-0.637,5.911-0.411c1.463,0.151,2.644,1.809,4.463,1.474
				c1.705-0.313,4.203-3.501,6.661-4.085c4.996-1.187,8.31-0.336,12.74-2.502c4.022-1.966,8.111-2.443,12.358-3.739
				c1.821-0.556,3.008-2.034,5.253-1.624c3.331,0.609,1.765,2.606,3.976,3.524c4.717,1.959,8.037-2.065,11.514-3.036
				c2.102-0.586,4.713,0.156,6.621-0.477c2.166-0.718,3.73-2.828,5.915-3.523c3.766-1.199,8.76-0.948,12.863-0.988
				c4.466-0.044,8.748,0.495,13.111,0.5c3.937,0.005,9.311-2.471,12.9-0.512c1.357,0.741,2.036,3.337,3.064,3.91
				c1.972,1.1,4.803,0.606,7.023,0.602c4.349-0.009,7.887,1.85,11.502,2.01c4.695,0.208,9.122-2.586,14.51-2.01
				c4.068,0.436,6.672,2.43,10.6,3c3.963,0.575,8.786-0.285,12.912-0.488c4.414-0.218,7.889,0.482,12.352,0.988
				c3.729,0.423,9.521,0.376,13.135-0.502c3.834-0.931,7.412-3.962,12.004-3.496c3.519,0.357,6.049,2.703,9.413,3.583
				c0.107,0.466,0.035,0.874,0.147,1.341c9.411,0.475,24.065-4.664,29.524,5.487c9.581,0.681,15.918-3.867,25.413,0.112
				c4.89,2.049,7.335,4.698,13.012,3.875c4.182-0.605,6.571-1.022,10.986-0.387c2.233,0.321,5.227-0.057,7.386,0.588
				c1.651,0.494,2.915,2.35,4.596,2.898c4.566,1.489,9.378-0.743,13.532-1.988c3.881-1.164,6.859-2.742,10.99-3.012
				c4.747-0.31,9.627-0.005,14.387,0c6.841,0.007,11.715-3.776,18.635-4.012c7.382-0.251,5.174,7.381,10.588,8.508
				c3.071,0.639,6.142-2.403,9.387-2.495c3.058-0.086,5.935,0.261,9.004-0.04c1.603-0.157,3.416,0.378,4.998,0.038
				c2.032-0.438,2.441-1.348,3.975-1.924c2.468-0.928,6.188-2.608,8.95-2c0.127,0.489-0.031,0.95,0.075,1.424v59.5H0L0.5,11.267z"/>
		</svg>
	)
}

export default Shape2

