

import {Component} from 'react';
import Shape1 from '../../../assets/svg/shape_1';
import Shape2 from '../../../assets/svg/shape_2';
import banner from '../../../assets/img/banner_3.jpg'
import bannerMovil from '../../../assets/img/banner_3_movil.jpg'

export default class Hiking extends Component 
{
    render()
    {
        return (
            <>
                <section id="bloque_home_4">
                    <div className="shape_3">
                            <Shape1/>
                    </div>
                    <div className="textos">
                            <h3>
                                        Hiking in the mountains
                            </h3>
                            <p>
                            Because in the end, you won’t remember the time you spent working in the office or mowing your lawn. Climb that goddamn mountain.
                            </p>
                    </div>
                    <div className="shape_4">
                            <Shape2/>
                    </div>          
                    <img className="sombra" src="<?php echo $enlace; ?>sources/img/sombra_1.png" alt=""/>
                </section>
                <section id="bloque_home_5">
                    <div className="shape_3">
                            <Shape1/>
                    </div>
                    <div className="textos">
                            <h3>
                                        EXPLORE<br/><span>THE WORLD</span>
                            </h3>
                    </div>
                    <picture>
                            <source srcSet={bannerMovil} media="(max-width: 600px)"/>
                            <source srcSet={banner}/>
                            <img src={banner} alt=""/>
                    </picture>
                    <div className="shape_4">
                            <Shape2/>
                    </div>
                </section>

            </>
        )
    }
}